<template>
  <div>
    <PageHeader />
    <Carrousel :title="$t('banner.kentaago.title')" :content="$t('banner.kentaago.content')" :showTabs="true" :extraClass="'smallImage'" :image="require(`@/assets/images/kentaa-premium.webp`)" />
    <article>
      <div class="container">
        <Breadcrumbs :current="'iRaiser Go'" />
        <div v-html="$t('content_pages.kentaa_go')"></div>
        <a href="https://www.iraiser.com" target="_blank" class="primary-btn pink d-inline-block min-width w-auto mt-3">
          <span class="initial-state w-100 d-inline-block notranslate"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>{{ $t('buttons.start_direct') }}</span>
          <span class="hover-state position-absolute"><i class="fas fa-angle-double-right no-background me-2 ms-0"></i>{{ $t("dictionary.know_more") }}</span>
        </a>
      </div>
    </article>

    <Customers platform="iRaiserGo" />
  </div>
</template>

<script>
// @ is an alias to /src
import "bootstrap/dist/css/bootstrap.min.css";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Carrousel from "@/components/Carrousel.vue";
import PageHeader from "@/components/PageHeader.vue";
import Customers from "@/components/Customers.vue";

export default {
  name: "About",

  components: {
    Breadcrumbs,
    Carrousel,
    Customers,
    PageHeader
  },

  created() {
    this.$setTitle(this.$t("seo.kentaa_go.title"));
    this.$setMeta([
      { name: "description", content: this.$t("seo.kentaa_go.description") },
      { property: "og:title", content: this.$t("seo.kentaa_go.title") },
      { property: "og:description", content: this.$t("seo.kentaa_go.description") },
      { property: "og:site_name", content: "Gogogiving.com" },
      { property: "og:site_name", content: "GogoGiving" },
      { property: "og:type", content: "website" },
      { name: "robots", content: "index,follow" }
    ]);
  }
};
</script>
